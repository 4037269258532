export * from './peticionarios'
export * from './ubicacion'
export * from './usuario'
export * from './administrador'
export * from './variables'
export * from './catalogos'
export * from './peticion'

export * from './organos'
export * from './improcedencia'
export * from './copias'
